import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from "../components/seo"
import styles from './work.module.scss'
import title_styles from '../pages/works.module.scss'

export const query = graphql`
    query($slug: String!) {
        markdown: markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            excerpt(pruneLength: 200)
            frontmatter {
                image: featured {
                    childImageSharp {
                        fluid(
                            maxWidth: 1000, 
                            maxHeight: 562, 
                            quality: 100) 
                        {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                title
                date
                tags
            }
            html
        }
    }
`

const Work = ({ data }) => {
    const { markdown: { excerpt, html, frontmatter } } = data
    const image = frontmatter.image ? frontmatter.image.childImageSharp.fluid : null

    return (
        <Layout>
            <SEO title={frontmatter.title} description={excerpt} keywords={frontmatter.tags} image={image}></SEO>
            <div className={title_styles.link}>
                <div className={title_styles.featured_work_item}>
                    <div>
                        <Img fluid={frontmatter.image.childImageSharp.fluid} alt="mindbuffer_logo" />
                    </div>
                    <div className={title_styles.titles}>
                        <div className={title_styles.section}>
                            <h1>{frontmatter.title}</h1>
                        </div>
                        <div className={title_styles.section}>
                            <h2>{frontmatter.tags[0]}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.post}>
                <div dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        </Layout>
    )
}

export default Work